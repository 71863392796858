import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SelectInput from "../../SelectInput";
import { useTranslation } from "react-i18next";
import {
  CATEGORY_SELECT_LIST,
  getTranslation,
  RESOURCE_CATEGORIES,
  showValidationError,
  transitionDirections,
  userLocalStorageKey,
} from "../../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectGlobalFontSize,
  selectPageInfo,
  selectPageView,
  selectTheme,
  setIsActionButtonDisabled,
} from "../../../store/slices/appSlice";
import {
  resetResourceInput,
  selectError,
  selectIsFirstSubmitted,
  selectOriginalInput,
  selectResourceInput,
  setError,
  setIsFirstSubmitted,
  setOriginalInput,
  setResourceInput,
} from "../../../store/slices/resourceInputSlice";
import {
  useCheckNameMutation,
  useCreateResourceMutation,
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
  useGetResourcesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { ValidationText } from "../../styles/inputs/NamesGroup.styles";
import { messageError, messageSuccess } from "../../../util/notification";
import {
  DISABLED_CHILDREN_CATEGORIES,
  checkResourceChanged,
  transformResourceInputRequest,
  transformResourceResponse,
  validateResourceForm,
} from "../../../util/asset-utils";
import {
  clearList,
  selectResourceImages,
  setList,
} from "../../../store/slices/resourceImageSlice";
import {
  clearAttachmentList,
  selectResourceAttachments,
  setAttachmentList,
} from "../../../store/slices/resourceAttachmentSlice";
import {
  useGetResourceAttachmentsQuery,
  useUploadResourceAttachmentMutation,
} from "../../../store/slices/api/resourceAttachmentsApiSlice";
import CharacteristicInputGroup from "./CharacteristicInputGroup";
import TypeInput from "./TypeInput";
import ParentTreeInput from "./ParentTreeInput";
import FunctionInput from "./FunctionInput";
import {
  CancelButton,
  CreateButton,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { DialogPaperProps } from "../../styles/general/General.styles";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import ErrorHandling from "../../common/ErrorHandling";
import { ParentInputWrapper } from "../../styles/assets/asset-form/AssetForm.styles";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import LocalResourceImagesList from "../asset-attachments/LocalResourceImagesList";
import LocalResourceAttachmentList from "../asset-attachments/LocalResourceAttachmentsList";
import TagsAssetFormSection from "./TagsAssetFormSection";
import {
  DEFAULT_COLOR,
  removeTags,
  resetTags,
  selectAllTags,
  setAutocompleteOpen,
  setCurrentColor,
  setGlobalTags,
  setInitialTags,
  setInputValue,
  setMarkedTag,
  setTags,
} from "../../../store/slices/tagsSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
  usePatchResourceTagsMutation,
} from "../../../store/slices/api/tagsSlice";
import { setCurrentResourceId } from "../../../store/slices/longPressSlice";
import { getSvgIcon } from "../../../util/icons";
import {
  useGetResourceImagesQuery,
  useUploadResourceImageMutation,
} from "../../../store/slices/api/resourceImagesApiSlice";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { setIsInDuplicate } from "../../../store/slices/assetListSlice";

const DuplicateAsset = ({ open, setOpen, resourceId }) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const resourceInput = useSelector(selectResourceInput);
  const originalInput = useSelector(selectOriginalInput);
  const error = useSelector(selectError);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const { isActionButtonDisabled } = useSelector(selectPageInfo);
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const resourceImagesRedux = useSelector(selectResourceImages);
  const resourceAttachmentsRedux = useSelector(selectResourceAttachments);
  const pageView = useSelector(selectPageView);
  const tagsRedux = useSelector(selectAllTags);

  // Refs
  const textRefType = useRef(null);
  const textRefBasicData = useRef(null);
  const iconRefType = useRef(null);
  const iconRefBasicData = useRef(null);

  // States
  const [openDiscard, setOpenDiscard] = useState(false);
  const [borderWidthType, setBorderWidthType] = useState("25%");
  const [borderWidthBasicData, setBorderWidthBasicData] = useState("25%");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const iconSize = globalFontSize * 1.2;

  // Mutations
  const [createResource] = useCreateResourceMutation();
  const [checkName] = useCheckNameMutation();
  const [uploadResourceImage] = useUploadResourceImageMutation();
  const [uploadResourceAttachment] = useUploadResourceAttachmentMutation();
  const [patchResourceTags] = usePatchResourceTagsMutation();

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResourceData,
    isError: isErrorResource,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    { skip: isSubmitting }
  );

  const {
    data: resourceAttachmentsData,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
  } = useGetResourceAttachmentsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    { skip: !Boolean(resourceId) || isSubmitting }
  );

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery({
    organizationId,
  });

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isError: isErrorResourceTags,
  } = useGetResourceTagsQuery({
    resourceid: resourceId,
    organizationId,
  });

  const { data: resourceImagesData, isLoading: isLoadingResourceImages } =
    useGetResourceImagesQuery(
      { resourceid: resourceId, organizationId },
      { skip: !resourceId || isSubmitting }
    );

  const {
    data: rootResourcesData,
    isLoading: isLoadingRootResources,
    isError: isErrorRootResources,
  } = useGetResourcesQuery(
    {
      organizationId,
    },
    { size: 1, index: 1 }
  );

  // Other variables
  const leftPadding = 16;
  const resourceImages = resourceImagesData ?? [];

  const {
    id,
    name,
    displayId,
    description,
    functionId,
    category,
    characteristics,
    typeId,
    hasTypeChanged,
  } = resourceInput;

  const alert = {
    title: getTranslation("CANCEL_UPDATE", t, i18n),
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const sortedSelectCategoryList = CATEGORY_SELECT_LIST.sort((a, b) => {
    if (a.id > b.id) {
      return 1;
    } else {
      return -1;
    }
  });

  const createButtonDisabled =
    isActionButtonDisabled ||
    !resourceInput?.name ||
    !resourceInput?.functionId ||
    ((resourceInput.category === RESOURCE_CATEGORIES.HARDWARE_ASSET ||
      resourceInput.category === RESOURCE_CATEGORIES.RACK) &&
      !resourceInput.typeId) ||
    isSubmitting;

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === Number(id));
  };

  const getCategoryDisabledOptions = () => {
    const parent = resourceInput.parent;

    const parentFunction = getFunction(parent?.functionId);
    const disabledChildrenCategories =
      DISABLED_CHILDREN_CATEGORIES[parentFunction?.category ?? ""];
    return disabledChildrenCategories.map((c) => ({
      value: c,
    }));
  };

  const categoryDisabledOptions = getCategoryDisabledOptions();

  // Handlers
  const handleResetTagsInput = () => {
    dispatch(setInputValue(""));
    dispatch(setError(""));
    dispatch(setAutocompleteOpen(false));
    dispatch(setCurrentColor(DEFAULT_COLOR));
    dispatch(setMarkedTag(null));
  };

  const handleDoneTags = () => {
    handleResetTagsInput();
    dispatch(removeTags());
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    dispatch(setIsFirstSubmitted(true));

    const parent = resourceInput.parent;
    const parentFunction = allFunctionsData.find(
      (f) => f.id === parent?.functionId
    );

    const isParentHardwareAsset =
      parentFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET;
    const isParentRack = parentFunction?.category === RESOURCE_CATEGORIES.RACK;
    const hasLocations = rootResourcesData?.totalRecord > 0;

    if (
      resourceInput.category === RESOURCE_CATEGORIES.LOCATION &&
      resourceInput.parentId &&
      (isParentHardwareAsset || isParentRack)
    ) {
      messageError(getTranslation("CHANGE_PARENT_VALIDATION", t, i18n));
      return;
    }

    if (
      resourceInput.category === RESOURCE_CATEGORIES.RACK &&
      resourceInput.parentId &&
      isParentHardwareAsset
    ) {
      messageError(
        getTranslation("CHANGE_HARDWARE_PARENT_VALIDATION", t, i18n)
      );
      return;
    }

    if (
      (resourceInput.category === "HARDWARE_ASSET" ||
        resourceInput.category === "RACK") &&
      !hasLocations
    ) {
      messageError(getTranslation("NO_LOCATIONS_VALIDATION", t, i18n));
      return;
    }

    const { error: evaluatedError, firstError } = validateResourceForm(
      resourceInput,
      t
    );

    const isValid =
      Object.keys(evaluatedError).length === 0 && !Boolean(error.name);

    if (!isValid) {
      if (firstError) {
        messageError(firstError);
      }

      if (error.name) {
        messageError(error.name);
      }
      dispatch(setIsActionButtonDisabled(true));
      return;
    }

    try {
      // Creates resource
      const resource = await createResource({
        resourceInput: transformResourceInputRequest(
          resourceInput,
          user?.region
        ),
        organizationId: user?.organizations?.find((o) => o.default).id,
        category: resourceInput.category.replace("_", "-").toLowerCase(),
      }).unwrap();

      // Upload resource images
      for (let i = 0; i < resourceImagesRedux.length; i++) {
        const resourceImage = resourceImagesRedux[i];

        const {
          imageCategory,
          name,
          description,
          file,
          selectedFile,
          isFromDb,
        } = resourceImage;

        let image = file;

        if (isFromDb) {
          const imageResponse = await fetch(BACKEND_URL + selectedFile, {
            headers: {
              authorization: `Bearer ${KeycloakService.getToken()}`,
            },
          });

          image = await imageResponse.blob();
        }

        const formData = new FormData();

        if (image) {
          formData.append("file", image);
          formData.append("fileName", name);
          formData.append("imageCategory", imageCategory);

          if (description) {
            formData.append("description", description);
          }

          await uploadResourceImage({
            formData,
            resourceid: resource?.id,
            organizationId: user?.organizations?.find((o) => o.default).id,
          }).unwrap();
        }
      }

      // Upload resource attachments
      for (let i = 0; i < resourceAttachmentsRedux.length; i++) {
        const resourceAttachment = resourceAttachmentsRedux[i];

        const {
          name: fileName,
          description,
          file,
          selectedFile,
          isFromDb,
        } = resourceAttachment;

        let attachment = file;

        if (isFromDb) {
          const attachmentResponse = await fetch(BACKEND_URL + selectedFile, {
            headers: {
              authorization: `Bearer ${KeycloakService.getToken()}`,
            },
          });

          attachment = await attachmentResponse.blob();
        }

        const formData = new FormData();

        if (attachment) {
          formData.append("file", attachment);
          formData.append("fileName", fileName);
          formData.append("description", description);

          await uploadResourceAttachment({
            formData,
            resourceid: resource?.id,
            organizationId: user?.organizations?.find((o) => o.default).id,
          }).unwrap();
        }
      }

      // Create tags for the resource
      const tagsToBeAdded = tagsRedux.map((t) => {
        return {
          displayName: t.name,
          color: t.color,
        };
      });

      const resourceFunction = getFunction(resourceData?.functionId);

      const resourceCategory = `${
        resourceFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
          ? "hardware-asset"
          : resourceFunction?.category.toLowerCase()
      }s`;

      const tagsPayload = [...tagsToBeAdded];

      await patchResourceTags({
        resourceid: resource?.id,
        organizationId: user?.organizations?.find((o) => o.default).id,
        resourceCategory,
        tags: tagsPayload,
      }).unwrap();

      handleDoneTags();

      if (pageView !== "column") {
        navigate(
          `/resources${
            resourceInput.parentId ? `/${resourceInput.parentId}` : ""
          }?direction=${transitionDirections.TOP_TO_BOTTOM}`
        );
      }

      messageSuccess(getTranslation("ASSET_DUPLICATED_SUCCESSFULLY", t, i18n));
      handleClose();
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to update a resource", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setIsInDuplicate(false));
  };

  const handleChangeName = (e) => {
    const newName = e.target.value.trimStart();

    dispatch(
      setResourceInput({
        ...resourceInput,
        name: newName,
      })
    );
  };

  const handleBlurName = async () => {
    try {
      const checkData = await checkName({
        name: resourceInput.name,
        resourceid: id,
        organizationId: user?.organizations?.find((o) => o.default).id,
      }).unwrap();

      let error = {};

      if (checkData) {
        if (!checkData.valid) {
          error =
            originalInput?.name !== resourceInput?.name
              ? {
                  name: `${getTranslation(
                    "UNIQUE_NAME_VALIDATION",
                    t,
                    i18n
                  )}: ${checkData.suggestion}`,
                }
              : {};
        }
      }

      dispatch(setError(error));
    } catch (error) {}
  };

  const handleChangeResourceCategory = (value) => {
    const newResourceCategory = value;

    dispatch(
      setResourceInput({
        ...resourceInput,
        category: newResourceCategory,
        functionId: null,
        typeId: null,
      })
    );
  };

  const handleChangeFunctionId = (value) => {
    const newFunctionId = value;
    dispatch(
      setResourceInput({
        ...resourceInput,
        functionId: newFunctionId,
        typeId: null,
        characteristics: [],
      })
    );
  };

  const handleChangeDisplayId = (e) => {
    const newDisplayId = e.target.value.trimStart();
    dispatch(setResourceInput({ ...resourceInput, displayId: newDisplayId }));
  };

  const handleChangeDescription = (e) => {
    const newDescription = e.target.value;
    dispatch(
      setResourceInput({ ...resourceInput, description: newDescription })
    );
  };

  const handleCancelCreate = () => {
    if (checkResourceChanged(originalInput, resourceInput, user.region)) {
      setOpenDiscard(true);
      return;
    }

    handleClose();
  };

  const handleDialogRef = (node) => {
    if (textRefType.current && iconRefType.current) {
      const textWidth = textRefType.current.offsetWidth;
      const totalWidth = textRefType.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefType.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthType(`${percentage}%`);
    }

    if (textRefBasicData.current && iconRefBasicData.current) {
      const textWidth = textRefBasicData.current.offsetWidth;
      const totalWidth = textRefBasicData.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRefBasicData.current.offsetWidth) /
          totalWidth) *
        100;

      setBorderWidthBasicData(`${percentage}%`);
    }
  };

  // Effects
  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateResourceForm(resourceInput, t);
    const isValid = Object.keys(evaluatedError).length === 0 && !error.name;

    dispatch(setIsActionButtonDisabled(!isValid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInput, error]);

  useEffect(() => {
    const handleLoadResourceData = async () => {
      if (open && resourceData) {
        try {
          const checkData = await checkName({
            name: resourceData.name,
            resourceid: resourceData.id,
            organizationId: user?.organizations?.find((o) => o.default).id,
          }).unwrap();

          if (checkData) {
            const resource = transformResourceResponse({
              ...resourceData,
              function: getFunction(resourceData.functionId),
            });

            const lastDashIndex = checkData.suggestion?.lastIndexOf("-");
            const suggestionNumber =
              checkData.suggestion?.substring(lastDashIndex);
            const displayIdSuggestion = `${resource.displayId}${suggestionNumber}`;

            // Load data into resource input
            const finalResourceInput = {
              ...resourceInput,
              ...resource,
              name: checkData.suggestion,
              displayId: resource.displayId ? displayIdSuggestion : "",
            };

            dispatch(setResourceInput(finalResourceInput));
            dispatch(setOriginalInput(finalResourceInput));
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    handleLoadResourceData();

    return () => {
      localStorage.removeItem(userLocalStorageKey("locations"));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, resourceData]);

  useEffect(() => {
    if (resourceImages) {
      let resultList = [];

      resourceImages?.forEach((resourceImage) => {
        const item = {
          imageCategory: resourceImage.imageCategory,
          selectedFile: resourceImage.uri,
          resourceid: resourceId,
          id: resourceImage.id,
          name: resourceImage.name,
          mimeType: resourceImage.name?.substring(
            resourceImage.name?.lastIndexOf(".")
          ),
          description: resourceImage.description,
          file: null,
          isFromDb: true,
        };

        resultList.push(item);
      });

      dispatch(setList(resultList));
    } else {
      dispatch(clearList());
    }

    return () => dispatch(clearList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImages?.length]);

  useEffect(() => {
    if (resourceAttachmentsData) {
      let resultList = [];

      resourceAttachmentsData?.forEach((resourceAttachment) => {
        const item = {
          selectedFile: resourceAttachment.uri,
          resourceid: resourceId,
          id: resourceAttachment.id,
          name: resourceAttachment.name,
          description: resourceAttachment.description,
          mimeType: resourceAttachment.name.substring(
            resourceAttachment.name.lastIndexOf(".")
          ),
          file: null,
          isFromDb: true,
        };

        resultList.push(item);
      });

      dispatch(setAttachmentList(resultList));
    } else {
      dispatch(clearAttachmentList());
    }

    return () => dispatch(clearAttachmentList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceAttachmentsData?.length]);

  useEffect(() => {
    if (tags) {
      const mappedTags = tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags?.length]);

  useEffect(() => {
    if (resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags?.length]);

  useEffect(() => {
    return () => {
      // Reset resource contents
      dispatch(resetResourceInput());
      dispatch(clearList());
      dispatch(clearAttachmentList());
      dispatch(resetTags());
      dispatch(setCurrentResourceId(null));
      setOpenDiscard(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorHandling
      isLoading={
        isLoadingRootResources ||
        isLoadingAttachments ||
        isLoadingFunctions ||
        isLoadingResourceData ||
        isLoadingTags ||
        isLoadingResourceTags ||
        isLoadingResourceImages ||
        isSubmitting
      }
      isError={
        isErrorRootResources ||
        isErrorAttachments ||
        isErrorFunctions ||
        isErrorResource ||
        isErrorTags ||
        isErrorResourceTags
      }
    >
      <>
        <Dialog
          id="duplicate-asset-dialog"
          fullWidth={true}
          maxWidth="md"
          PaperProps={DialogPaperProps}
          open={open}
          onClose={handleCancelCreate}
          ref={handleDialogRef}
        >
          {tabletMatches && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  sx={{ color: theme.palette.secondary.contrastText }}
                  variant="text"
                  onClick={handleCancelCreate}
                >
                  {getTranslation("CANCEL", t, i18n)}
                </Button>
              </Box>

              <Box>
                <DialogTitle>
                  {getTranslation("DUPLICATE_ASSET", t, i18n)}
                </DialogTitle>
              </Box>

              <Box>
                <Button
                  startDecorator={
                    isSubmitting ? (
                      <CircularProgress variant="solid" color="" />
                    ) : null
                  }
                  sx={{ color: theme.palette.secondary.contrastText }}
                  variant="text"
                  disabled={createButtonDisabled}
                  onClick={handleSubmit}
                >
                  {getTranslation("DONE", t, i18n)}
                </Button>
              </Box>
            </Box>
          )}

          {desktopMatches && (
            <DialogTitle>
              {getTranslation("DUPLICATE_ASSET", t, i18n)}
            </DialogTitle>
          )}

          <DialogContent>
            <ConfirmAlert
              isOpen={openDiscard}
              setIsOpen={setOpenDiscard}
              alert={alert}
              label="discard-changes"
              handleConfirm={handleClose}
            />

            {/* Basic data Accordion */}
            <StyledAccordion defaultExpanded>
              <StyledAccordionSummary
                value={borderWidthBasicData}
                expandIcon={
                  <div
                    style={{ marginTop: "5px", marginRight: "5px" }}
                    ref={iconRefBasicData}
                  >
                    {getSvgIcon(
                      "ARROW_RIGHT",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <SectionTitle
                  variant="body2"
                  istablet={1}
                  ref={textRefBasicData}
                >
                  {getTranslation("BASIC_DATA", t, i18n)}
                </SectionTitle>
              </StyledAccordionSummary>

              <StyledAccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={6}>
                    {/* Resource Name input */}
                    <Box>
                      <TextField
                        inputProps={{
                          "data-testid": "name-input",
                        }}
                        data-testid="name"
                        required={true}
                        label={getTranslation("NAME", t, i18n)}
                        value={name}
                        onChange={handleChangeName}
                        onBlur={handleBlurName}
                        error={(isFirstSubmitted && !name) || error.name}
                        autoFocus
                      />
                      {error.name && (
                        <ValidationText>{error.name}</ValidationText>
                      )}
                    </Box>

                    {/* Resource DisplayId input */}
                    <Box>
                      <TextField
                        inputProps={{
                          "data-testid": "display-id-input",
                        }}
                        data-testid="display-id"
                        label={getTranslation("DISPLAY_ID", t, i18n)}
                        value={displayId}
                        onChange={handleChangeDisplayId}
                      />
                    </Box>

                    {/* Resource Parent input */}
                    <ParentInputWrapper>
                      <ParentTreeInput
                        resourceId={resourceId}
                        category={category}
                        parentId={resourceInput.parentId}
                      />
                    </ParentInputWrapper>
                  </Grid>

                  <Grid item xs={6}>
                    {/* Resource Description input */}
                    <Box>
                      <TextField
                        inputProps={{
                          "data-testid": "notes-input",
                        }}
                        data-testid="notes"
                        label={getTranslation("NOTES", t, i18n)}
                        value={description}
                        onChange={handleChangeDescription}
                        multiline
                        rows={7.3}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </StyledAccordionDetails>
            </StyledAccordion>

            {/* Type Accordion */}
            <StyledAccordion defaultExpanded>
              <StyledAccordionSummary
                value={borderWidthType}
                expandIcon={
                  <div
                    style={{ marginTop: "5px", marginRight: "5px" }}
                    ref={iconRefType}
                  >
                    {getSvgIcon(
                      "ARROW_RIGHT",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <SectionTitle variant="body2" istablet={1} ref={textRefType}>
                  {getTranslation("TYPE", t, i18n)}
                </SectionTitle>
              </StyledAccordionSummary>

              <StyledAccordionDetails>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    {/* Resource Type input */}
                    <Box>
                      <SelectInput
                        fullWidth
                        selectLabelId="resourceCategory-label"
                        label={getTranslation("RESOURCE_CATEGORY", t, i18n)}
                        handleChange={handleChangeResourceCategory}
                        data={sortedSelectCategoryList}
                        value={category}
                        required={true}
                        error={isFirstSubmitted && !resourceInput.category}
                        disabledOptions={categoryDisabledOptions}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {/* Resource Function input */}
                    <Box>
                      <FunctionInput
                        required={true}
                        isFirstSubmitted={isFirstSubmitted}
                        changeHandler={handleChangeFunctionId}
                        functionId={functionId}
                        category={category}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    {/* Resource Type input  */}
                    <Box>
                      <TypeInput
                        required={
                          Boolean(resourceInput.category) &&
                          resourceInput.category !==
                            RESOURCE_CATEGORIES.LOCATION
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </StyledAccordionDetails>
            </StyledAccordion>

            {/* Resource Characteristics */}
            {functionId && (
              <Box>
                <CharacteristicInputGroup
                  characteristics={characteristics}
                  functionId={functionId}
                  typeId={typeId}
                  hasTypeChanged={hasTypeChanged}
                />
              </Box>
            )}

            {/* Images */}
            <LocalResourceImagesList mode="duplicate-asset" />

            {/* Attachments */}
            <LocalResourceAttachmentList mode="duplicate-asset" />

            {/* Tags */}
            <TagsAssetFormSection id={id} />
          </DialogContent>

          {desktopMatches && (
            <DialogActions>
              <CancelButton variant="outlined" onClick={handleCancelCreate}>
                {getTranslation("CANCEL", t, i18n)}
              </CancelButton>

              <CreateButton
                disabled={createButtonDisabled}
                onClick={handleSubmit}
              >
                {getTranslation("DONE", t, i18n)}
              </CreateButton>
            </DialogActions>
          )}
        </Dialog>
      </>
    </ErrorHandling>
  );
};

export default DuplicateAsset;
