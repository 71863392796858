import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SnackbarContent,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGetOrganizationRolesQuery } from "../../store/slices/api/organizationsApiSlice";
import { useInviteUserMutation } from "../../store/slices/api/userManagementSlice";
import { selectGlobalFontSize } from "../../store/slices/appSlice";
import { selectUser } from "../../store/slices/authSlice";
import {
  addInvitation,
  clearInvitations,
  deleteInvitation,
  editInvitation,
  selectInvitations,
} from "../../store/slices/invitationsSlice";
import {
  calculateActiveDuration,
  getTranslation,
  showValidationError,
  testInput,
} from "../../util/utils";
import { INVITATION_STATUS } from "../InvitationForm";
import { CancelInviteButton } from "../styles/assets/asset-form/CreateAsset.styles";
import { InviteUserButton } from "../styles/confirm-alert/ConfirmAlert.styles";
import {
  DialogPaperProps,
  SecondaryContrastButton,
} from "../styles/general/General.styles";
import {
  InvitationEmail,
  InvitationInputFormControl,
  InvitationSectionTitle,
  InviteButton,
} from "../styles/invitation/InvitationForm.styles";
import { InvitationListContainer } from "../styles/profile/InvitationForm.styles";
import { StyledDivider } from "../styles/profile/Profile.styles";
import {
  InviteMemberStyledTextField,
  LocalInvitationRow,
} from "../styles/profile/ProfileDesktop.styles";
import { InviteButtonText } from "../styles/profile/UserManagementPage.styles";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../store/slices/api/subscriptionsApiSlice";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { getSvgIcon } from "../../util/icons";
import { messageSuccess } from "../../util/notification";

const InviteMembers = ({ invitedUsers, organizationUsers, organizationId }) => {
  // Hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const invitations = useSelector(selectInvitations);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const organization = user?.organizations?.find(
    (o) => o.id === organizationId
  );

  const iconSize = globalFontSize * 1.2;
  const clearIconSize = globalFontSize;

  // States
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  // Mutations
  const [inviteUser] = useInviteUserMutation();

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  // Queries
  const { data: roles } = useGetOrganizationRolesQuery(organizationId);

  const { data: actualUsage } = useGetCurrentSubscriptionActualUsageQuery(
    {
      organizationId,
    },
    { skip: isRestricted }
  );

  // Handlers
  const handleSubmitInvitations = useCallback(async () => {
    const invitationData = [];

    invitations.forEach((invitation) => {
      const { email, role } = invitation;

      const userData = {
        email,
        roles: [role?.id],
      };

      const data = userData;

      invitationData.push({
        ...data,
        activeDuration: calculateActiveDuration(
          process.env.REACT_APP_ACTIVE_DURATION
        ), // calculates the activeDuration env variable in seconds
      });
    });

    try {
      await inviteUser({
        invitationData,
        organizationId,
      }).unwrap();

      dispatch(clearInvitations());

      messageSuccess(getTranslation("successfulSendInvitation", t, i18n));
    } catch (error) {
      showValidationError(error, t, i18n);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations, organizationId]);

  const handleAddInvitation = () => {
    const newInvitation = {
      email,
      role: roles?.find((r) => r.name === "COMMENTER"),
    };

    dispatch(addInvitation(newInvitation));

    // reset form inputs
    setEmail("");
  };

  const handleEditInvitation = (e, invitation) =>
    dispatch(
      editInvitation({
        ...invitation,
        role: { ...invitation.role, id: e.target.value },
      })
    );

  const handleDeleteInvitation = (email) => dispatch(deleteInvitation(email));

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Other variables
  const isEmailExistingTempInvitations = invitations?.some(
    (i) => i.email === email
  );
  const isEmailExistingOrganization = organizationUsers?.some(
    (iu) => iu.email === email
  );
  const isEmailExistingPendingInvitations = invitedUsers?.some(
    (iu) => iu.email === email && iu.status === INVITATION_STATUS.SENT
  );
  const isEmailValid = testInput.USER_EMAIL(email);
  const hasInvitations = invitations?.length > 0;

  const isAddDisabled =
    !isEmailValid ||
    user.email === email ||
    isEmailExistingTempInvitations ||
    isEmailExistingOrganization ||
    isEmailExistingPendingInvitations;

  const facts = actualUsage?.facts;
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);
  const sentInvitationsCount =
    invitedUsers?.filter((iu) => iu.status === INVITATION_STATUS.SENT)
      ?.length ?? 0;
  const organizationUsersCount = organizationUsers?.length ?? 0;
  const usersCurrentValue = sentInvitationsCount + organizationUsersCount;
  const totalInvitesCount = usersCurrentValue + invitations?.length;
  const isInviteDisabled =
    isRestricted || usersCurrentValue >= usersSubscriptionValue;
  const isSendInviteDisabled =
    invitations.length <= 0 || totalInvitesCount > usersSubscriptionValue;

  return (
    <>
      <SecondaryContrastButton
        id="invite-new-members"
        onClick={handleOpen}
        variant="text"
        disabled={isInviteDisabled}
      >
        <div style={{ marginRight: "5px", marginTop: "5px" }}>
          {getSvgIcon(
            "CREATE_NEW",
            iconSize,
            iconSize,
            isInviteDisabled
              ? theme.palette.secondary.dark
              : theme.palette.primary.contrastText
          )}
        </div>

        <InviteButtonText>
          {getTranslation("inviteNewMembers", t, i18n)}
        </InviteButtonText>
      </SecondaryContrastButton>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        PaperProps={DialogPaperProps}
        onClose={handleClose}
        scroll={"paper"}
      >
        <DialogTitle>{getTranslation("inviteNewMembers", t, i18n)}</DialogTitle>
        <DialogContent dividers={true}>
          <InviteMemberStyledTextField
            variant="outlined"
            label={getTranslation("emailAddressPlaceHolder", t, i18n)}
            type="email"
            placeholder={getTranslation("emailAddressPlaceHolder", t, i18n)}
            value={email}
            onChange={handleEmailChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InviteButton
                    id="invite-button"
                    onClick={handleAddInvitation}
                    disabled={isAddDisabled}
                  >
                    {getTranslation("ADD", t, i18n)}
                  </InviteButton>
                </InputAdornment>
              ),
            }}
          />
          {hasInvitations && (
            <>
              {totalInvitesCount > usersSubscriptionValue && (
                <SnackbarContent
                  sx={{
                    backgroundColor: "#f44336",
                    boxShadow: "none",
                    paddingBlock: 0,
                    marginBlock: "10px",
                  }}
                  message="Users limit exceeded"
                />
              )}

              <InvitationSectionTitle variant="h6">
                {getTranslation("INVITE", t, i18n)}
              </InvitationSectionTitle>
              {invitations.map((invitation) => (
                <InvitationListContainer key={invitation.email}>
                  <StyledDivider />
                  <LocalInvitationRow
                    container
                    id={`menu-item-${invitation.email}`}
                  >
                    <Grid item xs>
                      <InvitationEmail
                        variant="body1"
                        id={`label-${invitation.email}`}
                      >
                        {invitation.email}
                      </InvitationEmail>
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                      <InvitationInputFormControl variant="standard">
                        <Select
                          disableUnderline
                          labelId={`role-select-standard-label-${invitation.email}`}
                          id={`role-select-standard-${invitation.email}`}
                          value={invitation.role?.id}
                          onChange={(e) => handleEditInvitation(e, invitation)}
                          label={getTranslation("role", t, i18n)}
                        >
                          {roles
                            ?.filter((r) => r.name !== "OWNER")
                            ?.map((role) => (
                              <MenuItem key={role.id} value={role.id}>
                                {getTranslation(role.name, t, i18n)}
                              </MenuItem>
                            ))}
                        </Select>
                      </InvitationInputFormControl>

                      <IconButton
                        id={`remove-icon-${invitation.email}`}
                        data-testid={`remove-icon-${invitation.email}`}
                        onClick={() => handleDeleteInvitation(invitation.email)}
                      >
                        {getSvgIcon(
                          "CLEAR",
                          clearIconSize,
                          clearIconSize,
                          theme.palette.primary.main
                        )}
                      </IconButton>
                    </Grid>
                  </LocalInvitationRow>
                </InvitationListContainer>
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <CancelInviteButton variant="outlined" onClick={handleClose}>
            {getTranslation("CANCEL", t, i18n)}
          </CancelInviteButton>
          <InviteUserButton
            id="invite-members"
            onClick={() => {
              handleSubmitInvitations();
              handleClose();
            }}
            variant="contained"
            disabled={isSendInviteDisabled}
          >
            {getTranslation("INVITE", t, i18n)}
          </InviteUserButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteMembers;
