import { useGetOrganizationRolesQuery } from "../store/slices/api/organizationsApiSlice.js";
import { useUserRolePermissionsQuery } from "../store/slices/api/userManagementSlice.js";
import InvitationCard from "./InvitationCard.jsx";

const InvitationTable = ({ organizationId, userId, invitedUsers }) => {
  // Queries
  const { data: userRoles } = useUserRolePermissionsQuery(
    {
      organizationId,
      userId,
    },
    { skip: !organizationId }
  );

  const { data: roles } = useGetOrganizationRolesQuery(organizationId, {
    skip: !organizationId,
  });

  return (
    <div>
      {invitedUsers
        ?.filter((i) => i.status === "SENT")
        .map((invitation) => (
          <InvitationCard
            key={invitation.id}
            invitation={invitation}
            userRoles={userRoles}
            roles={roles}
            organizationId={organizationId}
          />
        ))}
    </div>
  );
};

export default InvitationTable;
