import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import {
  useGetOrganizationByIdQuery,
  useGetOrganizationUsersQuery,
  useUpdateOrganizationMutation,
} from "../../store/slices/api/organizationsApiSlice";
import {
  useGetAddressesByOrganizationIdQuery,
  usePatchAddressMutation,
  useSaveAddressMutation,
} from "../../store/slices/api/addressesApiSlice";
import { selectGlobalFontSize, selectTheme } from "../../store/slices/appSlice";
import { selectUser, setUser } from "../../store/slices/authSlice";
import {
  useGetInvitedUsersQuery,
  useUserRolePermissionsQuery,
} from "../../store/slices/api/userManagementSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  SORT_ORDER_NAME,
  testInput,
} from "../../util/utils";
import { messageError, messageSuccess } from "../../util/notification";
import { inputLabelProps } from "../inputs/BillingGroup";
import OrganizationHeaderDesktop from "./OrganizationHeaderDesktop";
import OrganizationUsersList from "../OrganizationUsersList";
import OrganizationDiscardChangesAlert from "./OrganizationDiscardChangesAlert";
import InvitationTable from "../InvitationTable";
import InviteMembers from "./InviteMembers";
import ErrorHandling from "../common/ErrorHandling";
import CountriesAutocomplete from "../inputs/CountriesAutocomplete";
import OrganizationDataStorageLocation from "./OrganizationDataStorageLocation";
import {
  ActiveOrganizationHeading,
  DesktopAvatarContainer,
  FlexGrowContainer,
  OrganizationAvatarTopSpaceContainer,
  OrganizationDesktopBoxContainer,
  OrganizationDesktopWrapper,
  OrganizationEditDesktopActions,
  OrganizationEditDesktopStickyFooter,
  OrganizationEditDiscardButton,
  OrganizationEditSaveButton,
  OrganizationUsersListBoxContainer,
  SectionTitleInvited,
  TextFieldsGridContainer,
} from "../styles/profile/ProfileDesktop.styles";
import { ValidationText } from "../styles/inputs/NamesGroup.styles";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { getSvgIcon } from "../../util/icons";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";

export const TABLET_SPACE = 2;
export const DESKTOP_SPACE = 3;

const DEFAULT_ORGANIZATION = {
  name: "",
  nickname: "",
  contactPhone: "",
  contactPerson: "",
  contactEmail: "",
};

const DEFAULT_ADDRESS = {
  addressLine1: "",
  addressLine2: "",
  postcode: "",
  city: "",
  state: "",
  country: "",
};

const OrganizationEditDesktop = ({ organizationId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [organization, setOrganization] = useState(DEFAULT_ORGANIZATION);
  const [originalOrganization, setOriginalOrganization] =
    useState(DEFAULT_ORGANIZATION);
  const [address, setAddress] = useState(DEFAULT_ADDRESS);
  const [originalAddress, setOriginalAddress] = useState(DEFAULT_ADDRESS);

  const [organizationNameTouched, setOrganizationNameTouched] = useState(false);
  const [organizationPhoneNumberTouched, setOrganizationPhoneTouched] =
    useState(false);
  const [
    organizationContactPersonTouched,
    setOrganizationContactPersonTouched,
  ] = useState(false);
  const [organizationContactEmailTouched, setOrganizationContactEmailTouched] =
    useState(false);
  const [organizationAddressLine1Touched, setOrganizationAddressLine1Touched] =
    useState(false);
  const [organizationPostcodeTouched, setOrganizationPostcodeTouched] =
    useState(false);
  const [organizationCityTouched, setOrganizationCityTouched] = useState(false);
  const [organizationCountryTouched, setOrganizationCountryTouched] =
    useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER_NAME.FIRST_NAME);
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabled, setDisabled] = useState(true);

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery(
    {
      organizationId,
      userId: user.id,
    },
    { skip: !organizationId }
  );

  const {
    data: invitedUsers,
    isLoading: isLoadingInvitedUsers,
    isError: isErrorInvitedUsers,
  } = useGetInvitedUsersQuery(organizationId, {
    skip: !hasAccess(
      "all",
      [permissions.USER_MANAGEMENT_VIEW],
      getPermissionsFromUserRoles(userRoles)
    ),
  });

  const {
    data: organizationUsers,
    isLoading: isLoadingOrganizationUsers,
    isError: isErrorOrganizationUsers,
  } = useGetOrganizationUsersQuery(organizationId, {
    skip: !hasAccess(
      "all",
      [permissions.USER_MANAGEMENT_VIEW],
      getPermissionsFromUserRoles(userRoles)
    ),
  });

  const {
    data: organizationData,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
  } = useGetOrganizationByIdQuery(organizationId, {
    skip: !Boolean(organizationId),
  });

  const {
    data: addressesData,
    isLoading: isLoadingAddresses,
    isError: isErrorAddresses,
  } = useGetAddressesByOrganizationIdQuery(
    { organizationId },
    {
      skip: !Boolean(organizationId),
    }
  );

  // Mutations
  const [updateOrganization, { isLoading: isLoadingUpdateOrganization }] =
    useUpdateOrganizationMutation();
  const [patchAddress, { isLoading: isLoadingPatchAddress }] =
    usePatchAddressMutation();
  const [saveAddress, { isLoading: isLoadingSaveAddress }] =
    useSaveAddressMutation();

  // Other variables
  const isValidOrganizationName = organizationNameTouched
    ? testInput.ORGANIZATION_NAME(organization.name)
    : true;

  const isValidOrganizationPhoneNumber = organizationPhoneNumberTouched
    ? testInput.PHONE_NUMBER(organization.contactPhone)
    : true;

  const isValidOrganizationContactPerson = organizationContactPersonTouched
    ? testInput.CONTACT_PERSON(organization.contactPerson)
    : true;

  const isValidOrganizationContactEmail = organizationContactEmailTouched
    ? testInput.CONTACT_EMAIL(organization.contactEmail)
    : true;

  const isValidAddressLine1 = organizationAddressLine1Touched
    ? testInput.ORGANIZATION_NAME(address.addressLine1)
    : true;

  const isValidPostcode = organizationPostcodeTouched
    ? testInput.ORGANIZATION_NAME(address.postcode)
    : true;

  const isValidCity = organizationCityTouched
    ? testInput.ORGANIZATION_NAME(address.city)
    : true;

  const isValidCountry = organizationCountryTouched
    ? Boolean(address.country)
    : true;

  const isInvalid =
    !isValidOrganizationName ||
    !isValidOrganizationPhoneNumber ||
    !isValidOrganizationContactPerson ||
    !isValidOrganizationContactEmail ||
    !isValidAddressLine1 ||
    !isValidPostcode ||
    !isValidCity ||
    !isValidCountry;

  const alert = {
    title: getTranslation("ORGANIZATION_KEEP_CHANGES_TITLE", t, i18n),
    content: getTranslation("ORGANIZATION_DISCARD_CHANGES", t, i18n),
    confirmTitle: getTranslation("SAVE_CHANGES", t, i18n),
    discardTitle: getTranslation("DISCARD", t, i18n),
    showConfirm: true,
  };

  const iconSize = globalFontSize * 1.2;
  const isUpdateOrganizationRestricted = hasAccess(
    "all",

    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  // Handlers
  const handleOpenSortOrderMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortOrderMenu = () => {
    setAnchorEl(null);
  };

  const handleSortOrderMembers = (order) => {
    setSortOrder(order);
  };

  const handleOrganizationNameChange = (event) => {
    const newOrganizationName = event.target.value;

    setOrganization({
      ...organization,
      name: newOrganizationName,
    });

    setOrganizationNameTouched(true);
  };

  const handleOrganizationNicknameChange = (event) => {
    const newOrganizationNickname = event.target.value;

    setOrganization({
      ...organization,
      nickname: newOrganizationNickname,
    });
  };

  const handleAddressLine1Change = (event) => {
    const newAddressLine1 = event.target.value;

    setAddress({
      ...address,
      addressLine1: newAddressLine1,
    });

    setOrganizationAddressLine1Touched(true);
  };

  const handleAddressLine2Change = (event) => {
    const newAddressLine2 = event.target.value;

    setAddress({
      ...address,
      addressLine2: newAddressLine2,
    });
  };

  const handlePostcodeChange = (event) => {
    const newPostcode = event.target.value;

    setAddress({
      ...address,
      postcode: newPostcode,
    });

    setOrganizationPostcodeTouched(true);
  };

  const handleCityChange = (event) => {
    const newCity = event.target.value;

    setAddress({
      ...address,
      city: newCity,
    });

    setOrganizationCityTouched(true);
  };

  const handleStateChange = (event) => {
    const newState = event.target.value;

    setAddress({
      ...address,
      state: newState,
    });
  };

  const handleCountryChange = (value) => {
    const newCountry = value;

    setOrganizationCountryTouched(true);

    setAddress({
      ...address,
      country: newCountry,
    });
  };

  const handleOrganizationPhoneNumberChange = (value) => {
    const newContactPhone = value;

    setOrganization({
      ...organization,
      contactPhone: newContactPhone,
    });

    setOrganizationPhoneTouched(true);
  };

  const handleOrganizationContactPersonChange = (event) => {
    const newContactPerson = event.target.value;

    setOrganization({
      ...organization,
      contactPerson: newContactPerson,
    });

    setOrganizationContactPersonTouched(true);
  };

  const handleOrganizationContactEmailChange = (event) => {
    const newContactEmail = event.target.value;

    setOrganization({
      ...organization,
      contactEmail: newContactEmail,
    });

    setOrganizationContactEmailTouched(true);
  };

  const handleConfirmAlert = () => {
    navigate("/profile/organization");
  };

  const handleSubmit = async () => {
    if (isInvalid) {
      messageError(getTranslation("organizationNameValidation", t, i18n));
      return;
    }

    try {
      navigate("/profile/organization");

      const data = await updateOrganization({
        organizationData: organization,
        organizationId,
      }).unwrap();

      if (data) {
        let filteredOrganizations = user?.organizations?.filter(
          (o) => o.id !== data.id
        );

        filteredOrganizations.push(data);

        if (addressesData && addressesData[0]) {
          await patchAddress({
            address,
            organizationId,
            addressId: addressesData[0].id,
          }).unwrap();
        } else {
          const addressData = {
            addressType: "BILLING",
            district: "District",
            ...address,
          };
          await saveAddress({ address: addressData, organizationId }).unwrap();
        }

        dispatch(
          setUser({
            ...user,
            organizations: filteredOrganizations,
          })
        );
      }

      messageSuccess(getTranslation("SAVED_CHANGES", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedUpdateOrganizationName", t, i18n));
    }
  };

  const checkHaveChanges = () => {
    if (originalOrganization.name !== organization.name) {
      return true;
    }

    if (originalOrganization.contactPhone !== organization.contactPhone) {
      return true;
    }

    if (originalOrganization.contactPerson !== organization.contactPerson) {
      return true;
    }

    if (originalOrganization.contactEmail !== organization.contactEmail) {
      return true;
    }

    if (originalOrganization.nickname !== organization.nickname) {
      return true;
    }

    if (originalAddress.addressLine1 !== address.addressLine1) {
      return true;
    }

    if (originalAddress.addressLine2 !== address.addressLine2) {
      return true;
    }

    if (originalAddress.postcode !== address.postcode) {
      return true;
    }

    if (originalAddress.city !== address.city) {
      return true;
    }

    if (originalAddress.state !== address.state) {
      return true;
    }

    if (originalAddress.country !== address.country) {
      return true;
    }

    return false;
  };

  // Other variables
  const haveChanges = checkHaveChanges();
  const openMenu = Boolean(anchorEl);

  // Effects
  useEffect(() => {
    if (organizationData) {
      setOrganization(organizationData);
      setOriginalOrganization(organizationData);
    }
  }, [organizationData]);

  useEffect(() => {
    if (addressesData && addressesData[0]) {
      setAddress(addressesData[0]);
      setOriginalAddress(addressesData[0]);
    }
  }, [addressesData]);

  useEffect(() => {
    const isValidOrganizationAddressLine1 = testInput.ORGANIZATION_NAME(
      address?.addressLine1 ?? ""
    );

    const isValidOrganizationPostcode = testInput.ORGANIZATION_NAME(
      address?.postcode ?? ""
    );

    const isValidOrganizationCity = testInput.ORGANIZATION_NAME(
      address?.city ?? ""
    );

    const isValidOrganizationCountry = Boolean(address?.country);

    const isValidOrganizationName = testInput.ORGANIZATION_NAME(
      organization?.name ?? ""
    );

    const isValidOrganizationPhone = testInput.PHONE_NUMBER(
      organization?.contactPhone ?? ""
    );

    const isValidOrganizationContactPerson = testInput.CONTACT_PERSON(
      organization?.contactPerson ?? ""
    );

    const isValidOrganizationContactEmail = testInput.CONTACT_EMAIL(
      organization?.contactEmail ?? ""
    );

    if (
      isValidOrganizationAddressLine1 &&
      isValidOrganizationPostcode &&
      isValidOrganizationCity &&
      isValidOrganizationCountry &&
      isValidOrganizationPhone &&
      isValidOrganizationName &&
      isValidOrganizationContactPerson &&
      isValidOrganizationContactEmail
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    organization?.name,
    organization?.contactPhone,
    organization?.contactPerson,
    organization?.contactEmail,
    address?.addressLine1,
    address?.postcode,
    address?.city,
    address?.country,
  ]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingUserRoles ||
        isLoadingInvitedUsers ||
        isLoadingOrganizationUsers ||
        isLoadingUpdateOrganization ||
        isLoadingPatchAddress ||
        isLoadingAddresses ||
        isLoadingOrganization ||
        isLoadingSaveAddress
      }
      isError={
        isErrorUserRoles ||
        isErrorInvitedUsers ||
        isErrorOrganizationUsers ||
        isErrorAddresses ||
        isErrorOrganization
      }
    >
      <OrganizationDesktopWrapper>
        <OrganizationDiscardChangesAlert
          isOpen={openConfirm}
          setIsOpen={setOpenConfirm}
          alert={alert}
          handleConfirm={handleSubmit}
          handleDiscard={handleConfirmAlert}
          disabled={disabled || isUpdateOrganizationRestricted}
        />
        <Menu
          slotProps={{
            paper: {
              elevation: 1,
              sx: {
                borderRadius: "8px",
              },
            },
          }}
          aria-labelledby="more-icon"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          id="menu-options"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseSortOrderMenu}
        >
          <MenuItem
            id="sort-members-first-name"
            onClick={() => {
              handleSortOrderMembers(SORT_ORDER_NAME.FIRST_NAME);
              handleCloseSortOrderMenu();
            }}
          >
            {getTranslation(SORT_ORDER_NAME.FIRST_NAME, t, i18n)}
          </MenuItem>
          <MenuItem
            id="sort-members-last-name"
            onClick={() => {
              handleSortOrderMembers(SORT_ORDER_NAME.LAST_NAME);
              handleCloseSortOrderMenu();
            }}
          >
            {getTranslation(SORT_ORDER_NAME.LAST_NAME, t, i18n)}
          </MenuItem>
        </Menu>

        <OrganizationDesktopBoxContainer>
          <OrganizationAvatarTopSpaceContainer>
            <DesktopAvatarContainer>
              <OrganizationHeaderDesktop organizationId={organizationId} />
            </DesktopAvatarContainer>
          </OrganizationAvatarTopSpaceContainer>

          <Stack rowGap={DESKTOP_SPACE}>
            <Grid
              container
              rowSpacing={{ sm: DESKTOP_SPACE, lg: TABLET_SPACE }}
              columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
            >
              <Grid item sm={12} lg={6}>
                <Grid
                  container
                  columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
                >
                  <Grid item sm={6}>
                    <TextField
                      id="organization-name-input"
                      type="text"
                      label={getTranslation("organizationName", t, i18n)}
                      placeholder={getTranslation("organizationName", t, i18n)}
                      name="organizationName"
                      value={organization.name}
                      onChange={handleOrganizationNameChange}
                      error={!isValidOrganizationName}
                      InputLabelProps={inputLabelProps}
                      required
                    />
                    <Box>
                      {!isValidOrganizationName && (
                        <ValidationText
                          data-testid="validation-text-organization-name"
                          variant="body5"
                          align="center"
                        >
                          {getTranslation(
                            "organizationNameValidation",
                            t,
                            i18n
                          )}
                        </ValidationText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      id="organization-nickname-input"
                      inputProps={{
                        "data-testid": "organization-nickname-input",
                      }}
                      type="text"
                      label={getTranslation("ORGANIZATION_NICKNAME", t, i18n)}
                      placeholder={getTranslation(
                        "ORGANIZATION_NICKNAME",
                        t,
                        i18n
                      )}
                      name="organizationNickname"
                      value={organization.nickname}
                      onChange={handleOrganizationNicknameChange}
                      InputLabelProps={inputLabelProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} lg={6}>
                <Grid
                  container
                  columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
                >
                  <Grid item sm={6}>
                    <TextField
                      inputProps={{
                        "data-testid": "organization-address-line-1-input",
                      }}
                      id="organization-address-line-1-input"
                      type="text"
                      label={getTranslation("ADDRESS_LINE1", t, i18n)}
                      required
                      placeholder={getTranslation("ADDRESS_LINE1", t, i18n)}
                      name="addressLine1"
                      value={address.addressLine1}
                      onChange={handleAddressLine1Change}
                      error={!isValidAddressLine1}
                      InputLabelProps={inputLabelProps}
                    />
                    <Box>
                      {!isValidAddressLine1 && (
                        <ValidationText
                          data-testid="validation-text-organization-address-line-1"
                          variant="body5"
                          align="center"
                        >
                          {getTranslation(
                            "ORGANIZATION_ADDRESS_LINE1_VALIDATION",
                            t,
                            i18n
                          )}
                        </ValidationText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      inputProps={{
                        "data-testid": "organization-address-line-2-input",
                      }}
                      id="organization-address-line-2-input"
                      type="text"
                      label={getTranslation("ADDRESS_LINE2", t, i18n)}
                      placeholder={getTranslation("ADDRESS_LINE2", t, i18n)}
                      name="addressLine2"
                      value={address.addressLine2}
                      onChange={handleAddressLine2Change}
                      InputLabelProps={inputLabelProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={{ sm: DESKTOP_SPACE, lg: TABLET_SPACE }}
              columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
            >
              <Grid item sm={12} lg={6}>
                <Grid
                  container
                  rowSpacing={{ sm: DESKTOP_SPACE, lg: TABLET_SPACE }}
                  columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
                >
                  <Grid item sm={6}>
                    <Grid container columnSpacing={{ sm: TABLET_SPACE, lg: 1 }}>
                      <Grid item sm={6}>
                        <TextField
                          inputProps={{
                            "data-testid": "organization-postal-code-input",
                          }}
                          id="organization-postal-code-input"
                          type="text"
                          label={getTranslation("POSTAL_CODE", t, i18n)}
                          required
                          placeholder={getTranslation("POSTAL_CODE", t, i18n)}
                          name="postalCode"
                          value={address.postcode}
                          onChange={handlePostcodeChange}
                          error={!isValidPostcode}
                          InputLabelProps={inputLabelProps}
                        />
                        <Box>
                          {!isValidPostcode && (
                            <ValidationText
                              data-testid="validation-text-organization-postal-code"
                              variant="body5"
                              align="center"
                            >
                              {getTranslation(
                                "ORGANIZATION_POSTAL_CODE_VALIDATION",
                                t,
                                i18n
                              )}
                            </ValidationText>
                          )}
                        </Box>
                      </Grid>
                      <Grid item sm={6}>
                        <TextField
                          inputProps={{
                            "data-testid": "organization-city-input",
                          }}
                          id="organization-city-input"
                          type="text"
                          label={getTranslation("CITY", t, i18n)}
                          required
                          placeholder={getTranslation("CITY", t, i18n)}
                          name="city"
                          value={address.city}
                          onChange={handleCityChange}
                          error={!isValidCity}
                          InputLabelProps={inputLabelProps}
                        />

                        <Box>
                          {!isValidCity && (
                            <ValidationText
                              data-testid="validation-text-organization-city"
                              variant="body5"
                              align="center"
                            >
                              {getTranslation(
                                "ORGANIZATION_CITY_VALIDATION",
                                t,
                                i18n
                              )}
                            </ValidationText>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      inputProps={{ "data-testid": "organization-state-input" }}
                      id="organization-state-input"
                      type="text"
                      label={getTranslation("STATE", t, i18n)}
                      placeholder={getTranslation("STATE", t, i18n)}
                      name="state"
                      value={address.state}
                      onChange={handleStateChange}
                      InputLabelProps={inputLabelProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} lg={6}>
                <Grid
                  container
                  columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
                >
                  <Grid item sm={6}>
                    <CountriesAutocomplete
                      value={address.country}
                      handleChange={handleCountryChange}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <PhoneInput
                      containerClass={currentTheme}
                      specialLabel={getTranslation("phoneNumber", t, i18n)}
                      inputClass={"phone-input"}
                      inputStyle={{
                        marginTop: "15px",
                        backgroundColor: theme.palette.primary.contrastText,
                        color: theme.palette.primary.main,
                        width: "100%",
                      }}
                      buttonStyle={{ marginTop: "15px" }}
                      dropdownStyle={{
                        backgroundColor: theme.palette.primary.contrastText,
                        color: theme.palette.primary.main,
                      }}
                      id="phone-input"
                      placeholder={getTranslation("phoneNumber", t, i18n)}
                      value={organization.contactPhone}
                      onChange={handleOrganizationPhoneNumberChange}
                      isValid={() => isValidOrganizationPhoneNumber}
                      defaultErrorMessage={getTranslation(
                        "phoneNumber",
                        t,
                        i18n
                      )}
                      error={!isValidOrganizationPhoneNumber}
                      inputProps={{
                        "data-testid": "organization-phone-input-onboarding",
                      }}
                    />
                    <Box>
                      {!isValidOrganizationPhoneNumber && (
                        <ValidationText variant="body5" align="center">
                          {getTranslation("phoneNumberValidation", t, i18n)}
                        </ValidationText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={{ sm: DESKTOP_SPACE, lg: TABLET_SPACE }}
              columnSpacing={DESKTOP_SPACE}
            >
              <Grid item sm={12} lg={6}>
                <Grid
                  container
                  columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
                >
                  <Grid item sm={6}>
                    <TextField
                      id="organization-contact-person-input"
                      type="text"
                      label={getTranslation("contactPerson", t, i18n)}
                      placeholder={getTranslation("contactPerson", t, i18n)}
                      name="contactPerson"
                      value={organization.contactPerson}
                      onChange={handleOrganizationContactPersonChange}
                      error={!isValidOrganizationContactPerson}
                      required
                      InputLabelProps={inputLabelProps}
                    />
                    <Box>
                      {!isValidOrganizationContactPerson && (
                        <ValidationText>
                          {getTranslation("userInfoValidation", t, i18n)}
                        </ValidationText>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      id="organization-contact-email-input"
                      type="text"
                      label={getTranslation("contactEmail", t, i18n)}
                      placeholder={getTranslation("contactEmail", t, i18n)}
                      name="contactEmail"
                      value={organization.contactEmail}
                      onChange={handleOrganizationContactEmailChange}
                      error={!isValidOrganizationContactEmail}
                      required
                      InputLabelProps={inputLabelProps}
                    />
                    <Box>
                      {!isValidOrganizationContactEmail && (
                        <ValidationText>
                          {getTranslation("contactEmailValidation", t, i18n)}
                        </ValidationText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} lg={6}>
                <OrganizationDataStorageLocation
                  organizationId={organizationId}
                />
              </Grid>
            </Grid>
          </Stack>

          <Stack marginTop={8} alignItems="center" spacing={5} direction="row">
            <ActiveOrganizationHeading variant="h5">
              {getTranslation("MEMBERS", t, i18n)}
            </ActiveOrganizationHeading>
            <Stack direction="row" alignItems="center">
              <SecondaryText>
                {`${getTranslation("SORT_BY", t, i18n)} ${getTranslation(
                  sortOrder,
                  t,
                  i18n
                ).toLowerCase()}`}
              </SecondaryText>
              <IconButton
                id="open-sorting-options"
                onClick={(event) => {
                  handleOpenSortOrderMenu(event);
                }}
              >
                {getSvgIcon(
                  "EXPAND",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </IconButton>
            </Stack>
          </Stack>
          <TextFieldsGridContainer
            container
            rowSpacing={{ sm: DESKTOP_SPACE, lg: TABLET_SPACE }}
            columnSpacing={{ sm: TABLET_SPACE, lg: DESKTOP_SPACE }}
          >
            <Grid item sm={12} md={6}>
              {hasAccess(
                "all",
                [permissions.USER_MANAGEMENT_ADD],
                getPermissionsFromUserRoles(userRoles)
              ) && (
                <InviteMembers
                  invitedUsers={invitedUsers}
                  organizationUsers={organizationUsers}
                  organizationId={organizationId}
                />
              )}
              <OrganizationUsersListBoxContainer>
                {hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_VIEW],
                  getPermissionsFromUserRoles(userRoles)
                ) &&
                  organizationUsers?.length > 0 && (
                    <OrganizationUsersList
                      organizationUsers={organizationUsers}
                      organizationId={organizationId}
                      sortOrder={sortOrder}
                    />
                  )}
              </OrganizationUsersListBoxContainer>
            </Grid>
            <Grid item sm={12} md={6}>
              <SectionTitleInvited variant="h6">
                {getTranslation("INVITED", t, i18n)}
              </SectionTitleInvited>
              {hasAccess(
                "all",
                [permissions.USER_MANAGEMENT_VIEW],
                getPermissionsFromUserRoles(userRoles)
              ) && (
                <InvitationTable
                  organizationId={organizationId}
                  userId={user.id}
                  invitedUsers={invitedUsers}
                />
              )}
            </Grid>
          </TextFieldsGridContainer>

          <FlexGrowContainer></FlexGrowContainer>

          <OrganizationEditDesktopStickyFooter>
            <Box>
              <Divider />
            </Box>

            <OrganizationEditDesktopActions>
              <OrganizationEditSaveButton
                onClick={handleSubmit}
                disabled={
                  !haveChanges ||
                  isInvalid ||
                  disabled ||
                  isUpdateOrganizationRestricted
                }
                variant="contained"
              >
                {getTranslation("SAVE_AND_CLOSE", t, i18n)}
              </OrganizationEditSaveButton>

              <OrganizationEditDiscardButton
                onClick={() => setOpenConfirm(true)}
                disabled={!haveChanges}
                variant="outlined"
              >
                {getTranslation("CANCEL_EDITING", t, i18n)}
              </OrganizationEditDiscardButton>
            </OrganizationEditDesktopActions>
          </OrganizationEditDesktopStickyFooter>
        </OrganizationDesktopBoxContainer>
      </OrganizationDesktopWrapper>
    </ErrorHandling>
  );
};

export default OrganizationEditDesktop;
