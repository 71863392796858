import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getSvgIcon } from "../../../util/icons";
import { getTranslation, SUBSCRIPTION_ERRORS } from "../../../util/utils";
import { useState } from "react";
import ErrorHandling from "../../common/ErrorHandling";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useDispatch } from "react-redux";
import {
  EXPORT_OPTIONS,
  IMPORT_EXPORT_FLOWS,
  IMPORT_MIME_TYPES,
  IMPORT_OPTIONS,
  MIN_STEP,
  nextWizardStep,
  previousWizardStep,
  resetWizard,
  selectExportIncludes,
  selectExportOption,
  selectFlow,
  selectImportFile,
  selectImportIncludes,
  selectImportOption,
  selectImportStrategy,
  selectWizardStep,
} from "../../../store/slices/importExportSlice";
import AssetsImportExportWizardTimeline from "./AssetsImportExportWizardTimelime";
import {
  AssetsImportExportWizardBackButton,
  AssetsImportExportWizardContinueButton,
  AssetsImportExportWizardTimeLineContainer,
} from "../../styles/assets/asset-list/AssetImportExport.styles";
import AssetImportExportWizardChooseOptionStep from "./asset-import-export-wizard/AssetImportExportWizardChooseOptionStep";
import AssetExportWizardSecondStep from "./asset-import-export-wizard/asset-export/AssetExportWizardSecondStep";
import { selectUser } from "../../../store/slices/authSlice";
import { messageError, messageSuccess } from "../../../util/notification";
import {
  useOrganizationExportMutation,
  useOrganizationExportXLSXMutation,
  useOrganizationImportMutation,
} from "../../../store/slices/api/organizationsApiSlice";
import AssetExportWizardFinalStep from "./asset-import-export-wizard/asset-export/AssetExportWizardFinalStep";
import AssetImportWizardSecondStep from "./asset-import-export-wizard/asset-import/AssetImportWizardSecondStep";
import AssetImportWizardThirdStep from "./asset-import-export-wizard/asset-import/AssetImportWizardThirdStep";
import AssetImportWizardFourthStep from "./asset-import-export-wizard/asset-import/AssetImportWizardFourthStep";
import AssetImportWizardFinalStep from "./asset-import-export-wizard/asset-import/AssetImportWizardFinalStep";

const AssetsImportExportWizard = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const wizardStep = useSelector(selectWizardStep);
  const flow = useSelector(selectFlow);
  const exportOption = useSelector(selectExportOption);
  const exportIncludes = useSelector(selectExportIncludes);
  const importOption = useSelector(selectImportOption);
  const importIncludes = useSelector(selectImportIncludes);
  const importStrategy = useSelector(selectImportStrategy);
  const importFile = useSelector(selectImportFile);

  // State
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  // Mutations
  const [organizationExport, { isLoading: isLoadingExport }] =
    useOrganizationExportMutation();

  const [organizationExportXLSX, { isLoading: isLoadingExportXLSX }] =
    useOrganizationExportXLSXMutation();

  const [organizationImport, { isLoading: isLoadingImport }] =
    useOrganizationImportMutation();

  // Other variables
  const iconSize = globalFontSize * 1.2;
  const organization = user?.organizations?.find((o) => o.default) ?? {};

  // Handlers
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(resetWizard());
    setErrors([]);
  };

  const handleImport = async () => {
    dispatch(nextWizardStep());

    try {
      const mimeType =
        importOption === IMPORT_OPTIONS.XLSX
          ? IMPORT_MIME_TYPES.XLSX
          : IMPORT_MIME_TYPES.ZIP;

      const { images, attachments, tags, graphicalObjects, customTypes } =
        importIncludes;

      const formData = new FormData();

      formData.append("file", importFile);
      formData.append("fileName", importFile.name);
      formData.append("mimeType", mimeType);

      const payload =
        importOption === IMPORT_OPTIONS.ZIP
          ? {
              organizationId: organization.id,
              includeGraphicalObjects: graphicalObjects,
              includeTags: tags,
              includeImages: images,
              includeAttachments: attachments,
              includeCustomTypes: customTypes,
              strategy: importStrategy?.toUpperCase(),
              formData,
            }
          : {
              organizationId: organization.id,
              includeTags: tags,
              includeCustomTypes: customTypes,
              strategy: importStrategy?.toUpperCase(),
              formData,
            };

      await organizationImport(payload).unwrap();

      messageSuccess(getTranslation("successfulImport", t, i18n));
    } catch (error) {
      if (error) {
        const subscriptionError = Object.values(SUBSCRIPTION_ERRORS).find((v) =>
          error?.data?.description?.includes(v)
        );

        if (
          Object.keys(SUBSCRIPTION_ERRORS).some(
            (key) => key === error?.data?.error
          ) ||
          subscriptionError
        ) {
          messageError(
            getTranslation(error?.data?.error || subscriptionError, t, i18n)
          );
        } else {
          if (error.error) {
            // Error is not coming from be
            const { originalStatus } = error;
            messageError(
              getTranslation(`ERROR_STATUS_${originalStatus}`, t, i18n)
            );
          } else if (error.data) {
            // Error is coming from be
            const { aborted, details, description } = error.data;

            if (aborted) {
              if (details) {
                // in order to show detailed error messages for issued resources
                const errors = details
                  .filter((d) => d.hasError)
                  .map((d) => {
                    return {
                      issuedResource: d.name,
                      messages: d.messages,
                    };
                  });
                // {name: string, messages: [{message}]}
                setErrors(errors);
              } else {
                // resource file is missing  then we show the description
                messageError(getTranslation(description, t, i18n));
              }
            }
          }
        }
      } else {
        messageError(getTranslation("failedImport", t, i18n));
      }
    }
  };

  const handleExport = async () => {
    const { images, attachments, tags, graphicalObjects, customTypes } =
      exportIncludes;

    const payload =
      exportOption === EXPORT_OPTIONS.ZIP
        ? {
            organizationId: organization?.id,
            includeImages: images,
            includeAttachments: attachments,
            includeGraphicalObjects: graphicalObjects,
            includeTags: tags,
            includeCustomTypes: customTypes,
            organizationName: organization?.nickname || organization?.name,
          }
        : {
            organizationId: organization?.id,
            includeTags: tags,
            includeCustomTypes: customTypes,
            organizationName: organization?.nickname || organization?.name,
          };

    try {
      const file =
        exportOption === EXPORT_OPTIONS.ZIP
          ? await organizationExport(payload).unwrap()
          : await organizationExportXLSX(payload).unwrap();

      const link = document.createElement("a");
      link.href = file;
      link.target = "_blank";
      link.download = "export";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(nextWizardStep());

      messageSuccess(getTranslation("successfulExport", t, i18n));
    } catch (error) {
      console.log(error);
      messageError(getTranslation("failedExport", t, i18n));
    }
  };

  const handleMoveBack = () => dispatch(previousWizardStep());

  const handleMoveNext = async () => {
    if (wizardStep === 1) {
      dispatch(nextWizardStep());
    } else if (wizardStep === 2) {
      if (flow === IMPORT_EXPORT_FLOWS.EXPORT) {
        await handleExport();
      } else {
        dispatch(nextWizardStep());
      }
    } else if (wizardStep === 3) {
      if (flow === IMPORT_EXPORT_FLOWS.EXPORT) {
        handleClose();
        return;
      }

      dispatch(nextWizardStep());
    } else if (wizardStep === 4) {
      await handleImport();
    } else if (wizardStep === 5) {
      handleClose();
    }
  };

  // Renderers
  const renderWizardContent = () => {
    if (wizardStep === 1) {
      return <AssetImportExportWizardChooseOptionStep />;
    } else if (wizardStep === 2) {
      return flow === IMPORT_EXPORT_FLOWS.EXPORT ? (
        <AssetExportWizardSecondStep />
      ) : (
        <AssetImportWizardSecondStep />
      );
    } else if (wizardStep === 3) {
      return flow === IMPORT_EXPORT_FLOWS.EXPORT ? (
        <AssetExportWizardFinalStep />
      ) : (
        <AssetImportWizardThirdStep />
      );
    } else if (wizardStep === 4) {
      return <AssetImportWizardFourthStep />;
    } else if (wizardStep === 5) {
      return (
        <AssetImportWizardFinalStep
          isLoadingImport={isLoadingImport}
          errors={errors}
        />
      );
    }

    return <p>Invalid wizard step</p>;
  };

  const renderWizardStepTitle = () => {
    if (wizardStep === 1) {
      return getTranslation("CHOOSE_IMPORT_EXPORT_OPTION", t, i18n);
    } else if (wizardStep === 2) {
      return flow === IMPORT_EXPORT_FLOWS.EXPORT
        ? getTranslation("EXPORT_SECOND_STEP_TITLE", t, i18n)
        : getTranslation("IMPORT_SECOND_STEP_TITLE", t, i18n);
    } else if (wizardStep === 3) {
      return flow === IMPORT_EXPORT_FLOWS.EXPORT
        ? getTranslation("EXPORT_FINAL_STEP_TITLE", t, i18n)
        : getTranslation("IMPORT_THIRD_STEP_TITLE", t, i18n);
    } else if (wizardStep === 4) {
      return getTranslation("IMPORT_FOURTH_STEP_TITLE", t, i18n);
    } else if (wizardStep === 5) {
      return isLoadingImport
        ? getTranslation("IMPORT_IN_PROGRESS_TITLE", t, i18n)
        : errors && errors.length > 0
        ? getTranslation("UNSUCCESSFUL_IMPORT_TITLE", t, i18n)
        : getTranslation("IMPORT_FINAL_STEP_TITLE", t, i18n);
    }

    return <p>Invalid wizard step</p>;
  };

  const renderWizardButtonContent = () => {
    if (wizardStep === 1) {
      return getTranslation("continueButton", t, i18n);
    } else if (wizardStep === 2) {
      return flow === IMPORT_EXPORT_FLOWS.EXPORT
        ? getTranslation("EXPORT", t, i18n)
        : getTranslation("continueButton", t, i18n);
    } else if (wizardStep === 3) {
      return flow === IMPORT_EXPORT_FLOWS.EXPORT
        ? getTranslation("EXIT", t, i18n)
        : getTranslation("continueButton", t, i18n);
    } else if (wizardStep === 4) {
      return getTranslation("IMPORT", t, i18n);
    } else if (wizardStep === 5) {
      return getTranslation("EXIT", t, i18n);
    }

    return <p>Invalid wizard step</p>;
  };

  return (
    <ErrorHandling
      isLoading={isLoadingExport || isLoadingExportXLSX}
      isError={false}
    >
      <>
        <Tooltip
          title={`${getTranslation("IMPORT", t, i18n)} / ${getTranslation(
            "EXPORT",
            t,
            i18n
          )}`}
        >
          <IconButton id="desktop-import-export-btn" onClick={handleClickOpen}>
            {getSvgIcon(
              "IMPORT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </IconButton>
        </Tooltip>

        <Dialog
          id="desktop-import-export-dialog"
          fullWidth={true}
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Box>
              <Grid container>
                <Grid item xs={10}>
                  <Box>
                    <Typography variant="h5">
                      {renderWizardStepTitle()}
                    </Typography>

                    {renderWizardContent()}
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  {!isLoadingImport && (
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <IconButton
                        id="close-reports-dialog"
                        aria-label="close"
                        onClick={handleClose}
                      >
                        {getSvgIcon(
                          "CLEAR",
                          iconSize,
                          iconSize,
                          theme.palette.secondary.contrastText
                        )}
                      </IconButton>
                    </Box>
                  )}

                  <AssetsImportExportWizardTimeLineContainer>
                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                      <AssetsImportExportWizardTimeline />
                    </Box>
                  </AssetsImportExportWizardTimeLineContainer>
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                {wizardStep > MIN_STEP && (
                  <AssetsImportExportWizardBackButton
                    id="back"
                    disabled={wizardStep <= MIN_STEP || wizardStep >= 5}
                    onClick={handleMoveBack}
                    variant="outlined"
                  >
                    {getTranslation("back", t, i18n)}
                  </AssetsImportExportWizardBackButton>
                )}

                <AssetsImportExportWizardContinueButton
                  id="continue"
                  disabled={
                    !flow ||
                    isLoadingImport ||
                    (wizardStep === 4 && !importFile)
                  }
                  onClick={handleMoveNext}
                  variant="contained"
                >
                  {isLoadingImport ? (
                    <CircularProgress
                      size={20}
                      sx={{ color: theme.palette.secondary.contrastText }}
                    />
                  ) : (
                    renderWizardButtonContent()
                  )}
                </AssetsImportExportWizardContinueButton>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    </ErrorHandling>
  );
};

export default AssetsImportExportWizard;
