import SectionHeader from "../../components/SectionHeader";
import { useGetFavoritesQuery } from "../../store/slices/api/assetManagementSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import ListInlineView from "../../components/assets/asset-list/ListInlineView";
import { SecondaryText } from "../../components/styles/assets/ListInlineView.styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";
import { CreateAssetButton } from "../../components/styles/assets/AssetList.styles";
import ErrorHandling from "../../components/common/ErrorHandling";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { Box } from "@mui/material";

const FavoritesSection = () => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: favoritesData,
    isLoading: isLoadingFavorites,
    isError: isErrorFavorites,
  } = useGetFavoritesQuery(
    {
      organizationId,
      index: 1,
      size: 5,
      sortBy: "favoured",
      order: "desc",
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  // Handlers
  const handleNavigateToFavorites = () => navigate("/favorites");
  const handleNavigateToAssets = () => navigate("/resources");

  return (
    <ErrorHandling isLoading={false} isError={isErrorFavorites}>
      <SectionHeader
        title="FAVORITES"
        titleId="favoritesHeader"
        actionName="OPENFAVORITES"
        actionNameId="openFavoritesHeader"
        action={handleNavigateToFavorites}
        actionNameVisible={favoritesData?.data?.length > 0}
      />

      <Box sx={{ position: "relative" }}>
        {favoritesData?.data?.length <= 0 && (
          <>
            <SecondaryText id="no-favorites" align="center">
              {getTranslation("NO_FAVORITES", t, i18n)}
            </SecondaryText>

            <CreateAssetButton
              id="open-assets-btn"
              onClick={handleNavigateToAssets}
            >
              {getTranslation("OPEN_ASSETS", t, i18n)}
            </CreateAssetButton>
          </>
        )}

        <div id="favorites-list">
          <ListInlineView
            list={favoritesData?.data}
            index={1}
            fetchMoreData={() => {}}
            resourcesData={favoritesData}
            isHome={true}
          />
        </div>

        {isLoadingFavorites && <LoadingSpinner />}
      </Box>
    </ErrorHandling>
  );
};

export default FavoritesSection;
