import { useEffect, useState } from "react";
import { MenuList, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import ProfilePreferenceItem from "../../components/ProfilePreferenceItem";
import {
  refreshUser,
  selectUser,
  uploadOrganizationImage,
} from "../../store/slices/authSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import {
  countries,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  localizeDate,
  permissions,
  transitionDirections,
} from "../../util/utils";
import UploadImage from "../../components/UploadImage";
import PhoneInput from "react-phone-input-2";
import {
  LeaveOrganizationButton,
  StyledDivider,
} from "../../components/styles/profile/Profile.styles";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import OrganizationDetailsHeader from "../../navigation/header/organization/OrganizationDetailsHeader";
import {
  useDeleteOrganizationImageMutation,
  useGetOrganizationByIdQuery,
  useGetOrganizationDataStorageLocationQuery,
  useGetOrganizationLogoQuery,
  useGetOrganizationUsersQuery,
  useLeaveOrganizationMutation,
  useUploadOrganizationImageMutation,
} from "../../store/slices/api/organizationsApiSlice";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import ErrorHandling from "../../components/common/ErrorHandling";
import { fetchCurrentUser } from "../../services/UserService";
import {
  HomePagePadding,
  SecondaryDarkText,
} from "../../components/styles/general/General.styles";
import { useGetAddressesByOrganizationIdQuery } from "../../store/slices/api/addressesApiSlice";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { useGetCurrentSubscriptionActualUsageQuery } from "../../store/slices/api/subscriptionsApiSlice";
import AppAccess from "../../components/common/AppAccess";

const OrganizationDetails = () => {
  // Global hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  // Selectors
  const user = useSelector(selectUser);

  // State
  const [initialFile, setInitialFile] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    organizationId,
    userId: user.id,
  });

  const {
    data: organizationData,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
  } = useGetOrganizationByIdQuery(organizationId);

  // Other variables
  const org = user?.organizations?.find((o) => o.id === organizationId);

  // Custom hooks
  const { isLoading, isRestricted, subscription, canFetchCurrentSubscription } =
    useCheckOrganizationRestricted(org);

  // Other variables
  const orgDisabled = !org?.owner && isRestricted;
  const isUpdateOrganizationRestricted = hasAccess(
    "all",

    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  const {
    data: organizationUsers,
    isLoading: isLoadingOrganizationUsers,
    isError: isErrorOrganizationUsers,
  } = useGetOrganizationUsersQuery(organizationId, {
    skip:
      orgDisabled ||
      !hasAccess(
        "all",
        [permissions.USER_MANAGEMENT_VIEW],
        getPermissionsFromUserRoles(userRoles)
      ),
  });

  const { data: actualUsage, isLoading: isLoadingActualUsage } =
    useGetCurrentSubscriptionActualUsageQuery(
      {
        organizationId: org?.id,
      },
      {
        skip: isRestricted,
      }
    );

  // Other variables
  const facts = actualUsage?.facts;
  const assetsUsage = facts?.find((f) => f.name === "ASSET_COUNT");
  const usersUsage = facts?.find((f) => f.name === "USER_COUNT");
  const assetsSubscriptionValue = Number(assetsUsage?.subscriptionValue);
  const assetsCurrentValue = Number(assetsUsage?.currentValue);
  const usersSubscriptionValue = Number(usersUsage?.subscriptionValue);
  const usersCurrentValue = organizationUsers?.length ?? 0;

  const {
    data: orgDataStorageLocation,
    isLoading: isLoadingOrgDataStorageLocation,
    isError: isErrorDataStorageLocation,
  } = useGetOrganizationDataStorageLocationQuery(
    { organizationId },
    {
      skip:
        orgDisabled ||
        !hasAccess(
          "all",
          [permissions.ORG_MANAGEMENT_EDIT],
          getPermissionsFromUserRoles(userRoles)
        ),
    }
  );

  const { data: organizationLogo } = useGetOrganizationLogoQuery(
    {
      logoUri: organizationData?.logoUri,
      thumbnail: true,
    },
    {
      skip: !Boolean(organizationData?.logoUri),
    }
  );

  const {
    data: addressesData,
    isLoading: isLoadingAddresses,
    isError: isErrorAddresses,
  } = useGetAddressesByOrganizationIdQuery(
    { organizationId },
    { skip: !Boolean(organizationId) }
  );

  // Mutations
  const [uploadImage, { isLoading: isLoadingUploadImage }] =
    useUploadOrganizationImageMutation();

  const [leaveOrganization, { isLoading: isLoadingLeaveOrganization }] =
    useLeaveOrganizationMutation();

  const [
    deleteOrganizationImage,
    { isLoading: isLoadingDeleteOrganizationImage },
  ] = useDeleteOrganizationImageMutation();

  // Other variables
  let transitionDirection = searchParams.get("direction");

  // Handlers
  const submitUploadedImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      let resp = await uploadImage({
        formData,
        organizationId: organizationData?.id,
      }).unwrap();

      dispatch(
        uploadOrganizationImage({
          logoUri: resp.logoUri,
          organizationId: organizationData?.id,
        })
      );

      messageSuccess(
        getTranslation("successfulUpdateOrganizationLogo", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateOrganizationLogo", t, i18n));
    }
  };

  const handleDeleteOrganizationImage = async () => {
    try {
      await deleteOrganizationImage({ organizationId }).unwrap();

      dispatch(
        uploadOrganizationImage({
          logoUri: "",
          organizationId,
        })
      );

      messageSuccess(getTranslation("ORGANIZATION_IMAGE_DELETED", t, i18n));
    } catch (error) {
      console.error("Failed to delete profile image");
    }
  };

  const handleUpdateNameClick = () => {
    navigate(
      "/profile/organization/" +
        organizationData?.id +
        `/update-organization-name?direction=${transitionDirections.BOTTOM_TO_TOP}`
    );
  };

  const handleUpdatePhoneNumberClick = (e) => {
    navigate(
      "/profile/organization/" +
        organizationData?.id +
        `/update-organization-phone?direction=${transitionDirections.BOTTOM_TO_TOP}`
    );
  };

  const handleUpdateOrganizationContactDetailsClick = () => {
    navigate(
      "/profile/organization/" +
        organizationData?.id +
        `/update-organization-contact-details?direction=${transitionDirections.BOTTOM_TO_TOP}`
    );
  };

  const handleUserManagementClick = () => {
    navigate(
      "/profile/organization/" +
        organizationData?.id +
        `/user-management?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  const handleUpdateOrganizationAddressClick = () => {
    navigate(
      `/profile/organization/${organizationData?.id}/update-organization-address/${addressesData[0]?.id}?direction=${transitionDirections.BOTTOM_TO_TOP}`
    );
  };

  const handleLeaveOrganization = async () => {
    try {
      await leaveOrganization(organizationId).unwrap();
      const response = await fetchCurrentUser();
      dispatch(refreshUser(response.data));
      messageSuccess(getTranslation("successfulLeaveOrganization", t, i18n));
      navigate("/profile/organization");
    } catch (error) {
      messageError(getTranslation("failedLeaveOrganization", t, i18n));
    }
  };

  const handleLeaveConfirm = () => {
    setOpenConfirm(true);
  };

  const goBackHandler = () =>
    navigate(
      `/profile/organization?direction=${transitionDirections.LEFT_TO_RIGHT}`
    );

  const getOrganizationName = () => {
    const { name, nickname } = organizationData ?? {};
    let organizationName = name;
    if (nickname) {
      organizationName = `${name}, ${nickname}`;
    }

    return organizationName;
  };

  const getAddress = () => {
    let address;

    if (addressesData && addressesData[0]) {
      const {
        addressLine1,
        addressLine2,
        postcode,
        city,
        state,
        country: countryCode,
      } = addressesData[0];
      if (addressLine1) {
        address = addressLine1;
      }

      if (addressLine2) {
        address = `${address}, ${addressLine2}`;
      }

      if (postcode) {
        address = `${address}, ${postcode}`;
      }

      if (city) {
        address = `${address}, ${city}`;
      }

      if (state) {
        address = `${address}, ${state}`;
      }

      if (countryCode) {
        const country = countries.find((c) => c.code === countryCode);
        address = `${address}, ${getTranslation(country.label, t, i18n)}`;
      }
    }

    return address;
  };

  // Effects
  useEffect(() => {
    if (organizationLogo) {
      setInitialFile(organizationLogo);
    }
  }, [organizationLogo]);

  useEffect(() => {
    const valuesLoaded = !(isLoadingOrganizationUsers || isLoadingActualUsage);
    const isLimitExceeded = usersCurrentValue > usersSubscriptionValue;
    const showAlarmMessage = !isRestricted && valuesLoaded && isLimitExceeded;

    if (showAlarmMessage) {
      messageError(getTranslation("LIMIT_EXCEEDED_USER", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    usersCurrentValue,
    usersSubscriptionValue,
    isLoadingOrganizationUsers,
    isLoadingActualUsage,
    isRestricted,
  ]);

  const organizationName = getOrganizationName();
  const address = getAddress();
  const contact = `${organizationData?.contactPerson}, ${organizationData?.contactEmail}`;
  const dataStorageLocation = orgDataStorageLocation
    ? i18n.exists(orgDataStorageLocation.displayId)
      ? t(orgDataStorageLocation.displayId)
      : orgDataStorageLocation.name
    : "";
  const members =
    organizationUsers?.length > 0
      ? organizationUsers?.length
      : "No other members";

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingUserRoles ||
          isLoadingOrganizationUsers ||
          isLoadingLeaveOrganization ||
          isLoadingOrgDataStorageLocation ||
          isLoadingUploadImage ||
          isLoadingDeleteOrganizationImage ||
          isLoadingAddresses ||
          isLoadingOrganization ||
          isLoading ||
          isLoadingActualUsage
        }
        isError={
          isErrorUserRoles ||
          isErrorOrganizationUsers ||
          isErrorDataStorageLocation ||
          isErrorAddresses ||
          isErrorOrganization
        }
      >
        <PageTransition direction={transitionDirection}>
          <ConfirmAlert
            isOpen={openConfirm}
            setIsOpen={setOpenConfirm}
            alert={{
              content:
                getTranslation("organizationLeaveMessage", t, i18n) +
                " " +
                (organizationData?.nickname || organizationData?.name) +
                " " +
                getTranslation("organization", t, i18n).toLowerCase(),
              confirmTitle: getTranslation("organizationConfirmTitle", t, i18n),
              closeTitle: getTranslation("CANCEL", t, i18n),
              showConfirm: true,
            }}
            label="leave-organization"
            handleConfirm={handleLeaveOrganization}
          />
          <OrganizationDetailsHeader
            goBackHandler={goBackHandler}
            title={organizationData?.nickname || organizationData?.name}
          />
          <HomePagePadding>
            <UploadImage
              initialFile={organizationData?.logoUri ? initialFile : null}
              submitUploadedImage={submitUploadedImage}
              uploadTitle={
                initialFile && organizationData?.logoUri
                  ? "EDIT_LOGO"
                  : "ADD_LOGO"
              }
              checker="all"
              permissions={[permissions.ORG_MANAGEMENT_EDIT]}
              userRoles={userRoles}
              deleteImage={handleDeleteOrganizationImage}
              disabled={isUpdateOrganizationRestricted}
            />
            <MenuList dense>
              <StyledDivider sx={{ marginBottom: "8px" }} />
              <ProfilePreferenceItem
                handleClick={handleUpdateNameClick}
                label={getTranslation("name", t, i18n)}
                labelId="btn-organization-name"
                menuId="btn-menu-item-organization-name"
                value={organizationName}
                checker="all"
                permissions={[permissions.ORG_MANAGEMENT_EDIT]}
                userRoles={userRoles}
              />
              <StyledDivider />
              <ProfilePreferenceItem
                handleClick={handleUpdateOrganizationAddressClick}
                label={getTranslation("ADDRESS", t, i18n)}
                labelId="btn-address"
                menuId="btn-menu-item-address"
                value={address}
                checker="all"
                permissions={[permissions.ORG_MANAGEMENT_EDIT]}
                userRoles={userRoles}
              />
              <StyledDivider />
              <ProfilePreferenceItem
                handleClick={handleUpdatePhoneNumberClick}
                label={getTranslation("phoneNumber", t, i18n)}
                labelId="btn-organization-contact-phone"
                menuId="btn-menu-item-organization-contact-phone"
                value={
                  <PhoneInput
                    containerClass="no-special-label"
                    inputStyle={{
                      background: "transparent",
                      color: theme.palette.text.secondary,
                      border: 0,
                      textAlign: "end",
                      padding: 0,
                      display: "flex",
                      width: "100%",
                      cursor: "pointer",
                      position: "relative",
                      zIndex: -1,
                    }}
                    disabled
                    buttonStyle={{ display: "none" }}
                    id="phone-number"
                    value={organizationData?.contactPhone}
                  />
                }
                checker="all"
                permissions={[permissions.ORG_MANAGEMENT_EDIT]}
                userRoles={userRoles}
              />
              <StyledDivider />

              <ProfilePreferenceItem
                handleClick={handleUpdateOrganizationContactDetailsClick}
                label={"CONTACT"}
                labelId="btn-organization-contact-details"
                menuId="btn-menu-item-organization-contact-details"
                value={contact}
                checker="all"
                permissions={[permissions.ORG_MANAGEMENT_EDIT]}
                userRoles={userRoles}
              />

              {!orgDisabled &&
                hasAccess(
                  "all",
                  [permissions.ORG_MANAGEMENT_EDIT],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <>
                    <StyledDivider />
                    <ProfilePreferenceItem
                      label={getTranslation("DATA_LOCATION", t, i18n)}
                      labelId="btn-data-storage-location"
                      menuId="btn-menu-item-data-storage-location"
                      value={dataStorageLocation}
                      userRoles={userRoles}
                      showrighticon={false}
                    />
                    <SecondaryDarkText
                      marginLeft="8px"
                      marginBottom="8px"
                      variant="body2"
                    >
                      {getTranslation("ORG_GEO_LOCATION_STORING", t, i18n)}
                    </SecondaryDarkText>
                  </>
                )}

              {!orgDisabled &&
                hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_VIEW],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <>
                    <StyledDivider sx={{ marginBottom: "8px" }} />
                    <ProfilePreferenceItem
                      handleClick={handleUserManagementClick}
                      label={"members"}
                      labelId="btn-user-organization"
                      menuId="btn-menu-item-user-organization"
                      value={members}
                      checker="all"
                      permissions={[permissions.USER_MANAGEMENT_VIEW]}
                      userRoles={userRoles}
                    />
                  </>
                )}

              {!isRestricted &&
                hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_VIEW],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <>
                    <StyledDivider sx={{ marginBottom: "8px" }} />
                    <ProfilePreferenceItem
                      label={"Subscription"}
                      labelId="btn-subscription-organization"
                      menuId="btn-menu-item-subscription-organization"
                      value={getTranslation(
                        org?.subscription?.tier?.name,
                        t,
                        i18n
                      )}
                      checker="all"
                      permissions={[permissions.USER_MANAGEMENT_VIEW]}
                      userRoles={userRoles}
                    />
                  </>
                )}

              {!isRestricted &&
                hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_VIEW],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <>
                    <StyledDivider sx={{ marginBottom: "8px" }} />
                    <ProfilePreferenceItem
                      label={"Users"}
                      labelId="btn-users-usage-organization"
                      menuId="btn-menu-item-users-usage-organization"
                      value={`${usersCurrentValue}/${usersSubscriptionValue}`}
                      checker="all"
                      permissions={[permissions.USER_MANAGEMENT_VIEW]}
                      userRoles={userRoles}
                    />
                  </>
                )}

              {!isRestricted &&
                hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_VIEW],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <>
                    <StyledDivider sx={{ marginBottom: "8px" }} />
                    <ProfilePreferenceItem
                      label={"assets"}
                      labelId="btn-assets-usage-organization"
                      menuId="btn-menu-item-assets-usage-organization"
                      value={`${assetsCurrentValue}/${assetsSubscriptionValue}`}
                      checker="all"
                      permissions={[permissions.USER_MANAGEMENT_VIEW]}
                      userRoles={userRoles}
                    />
                  </>
                )}

              {!isRestricted &&
                canFetchCurrentSubscription &&
                hasAccess(
                  "all",
                  [permissions.USER_MANAGEMENT_VIEW],
                  getPermissionsFromUserRoles(userRoles)
                ) && (
                  <>
                    <StyledDivider sx={{ marginBottom: "8px" }} />
                    <ProfilePreferenceItem
                      label={"VALID_UNTIL"}
                      labelId="btn-subscription-valid-until-organization"
                      menuId="btn-menu-item-subscription-valid-until-organization"
                      value={
                        subscription?.validUntil
                          ? localizeDate(subscription?.validUntil, user?.region)
                          : "N/A"
                      }
                      checker="all"
                      permissions={[permissions.USER_MANAGEMENT_VIEW]}
                      userRoles={userRoles}
                    />
                  </>
                )}

              <StyledDivider />

              {!organizationData?.owner && !org?.restricted && (
                <LeaveOrganizationButton
                  id="leave-organization"
                  data-testid="leave-organization"
                  onClick={handleLeaveConfirm}
                  color="error"
                  variant="contained"
                >
                  {getTranslation("leaveOrganization", t, i18n)}
                </LeaveOrganizationButton>
              )}
            </MenuList>
          </HomePagePadding>
        </PageTransition>
      </ErrorHandling>
    </AppAccess>
  );
};

export default OrganizationDetails;
