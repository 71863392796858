import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import {
  useDeleteOrganizationImageMutation,
  useGetOrganizationLogoQuery,
  useUploadOrganizationImageMutation,
} from "../../store/slices/api/organizationsApiSlice";
import { useUserRolePermissionsQuery } from "../../store/slices/api/userManagementSlice";
import {
  selectUser,
  uploadOrganizationImage,
} from "../../store/slices/authSlice";
import { messageError, messageSuccess } from "../../util/notification";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
} from "../../util/utils";
import {
  ProfileAvatarContainer,
  RemoveImageButton,
} from "../styles/profile/ProfileDesktop.styles";
import UploadImageDesktop from "./UploadImageDesktop";
import ErrorHandling from "../common/ErrorHandling";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";

const OrganizationHeaderDesktop = ({ organizationId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const organization = user?.organizations?.find(
    (o) => o.id === organizationId
  );

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(organization);

  // Queries
  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery(
    {
      organizationId,
      userId: user.id,
    },
    { skip: !organizationId }
  );

  const { data: organizationLogo } = useGetOrganizationLogoQuery(
    {
      logoUri: organization?.logoUri,
      thumbnail: true,
    },
    {
      skip: !Boolean(organization?.logoUri),
    }
  );

  // Mutations
  const [uploadImage, { isLoading: isLoadingUploadImage }] =
    useUploadOrganizationImageMutation();

  const [
    deleteOrganizationImage,
    { isLoading: isLoadingDeleteOrganizationImage },
  ] = useDeleteOrganizationImageMutation();

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Other variables
  const confirmAlert = {
    content: getTranslation("ASSET_IMAGE_DELETE_MESSAGE", t, i18n),
    confirmTitle: getTranslation("DELETE_ASSET_IMAGE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };
  const isUpdateOrganizationRestricted = hasAccess(
    "all",
    [permissions.ORG_MANAGEMENT_SUBSCRIPTION_EDIT],
    getPermissionsFromUserRoles(userRoles)
  )
    ? false
    : isRestricted;

  // Handlers
  const submitUploadedImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      let resp = await uploadImage({
        formData,
        organizationId: organization.id,
      }).unwrap();

      dispatch(
        uploadOrganizationImage({
          logoUri: resp.logoUri,
          organizationId: organization.id,
        })
      );

      messageSuccess(
        getTranslation("successfulUpdateOrganizationLogo", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateOrganizationLogo", t, i18n));
    }
  };

  const handleDeleteOrganizationImage = async () => {
    try {
      dispatch(
        uploadOrganizationImage({
          logoUri: "",
          organizationId,
        })
      );
      await deleteOrganizationImage({ organizationId }).unwrap();

      messageSuccess(getTranslation("ORGANIZATION_IMAGE_DELETED", t, i18n));
    } catch (error) {
      dispatch(
        uploadOrganizationImage({
          logoUri: organization?.logoUri,
          organizationId,
        })
      );
      console.error("Failed to delete organization logo");
    }
  };

  const handleConfirm = async () => {
    await handleDeleteOrganizationImage();
  };

  return (
    <ErrorHandling
      isLoading={
        isLoadingUserRoles ||
        isLoadingUploadImage ||
        isLoadingDeleteOrganizationImage
      }
      isError={isErrorUserRoles}
    >
      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={confirmAlert}
        label="delete-image"
        handleConfirm={handleConfirm}
      />

      <ProfileAvatarContainer>
        <UploadImageDesktop
          initialFile={
            organization?.logoUri && organizationLogo ? organizationLogo : null
          }
          submitUploadedImage={submitUploadedImage}
          uploadTitle={
            organizationLogo && organization?.logoUri ? "EDIT_LOGO" : "ADD_LOGO"
          }
          checker="all"
          permissions={[permissions.ORG_MANAGEMENT_EDIT]}
          userRoles={userRoles}
          deleteImage={handleDeleteOrganizationImage}
          orgId={organizationId}
          disabled={isUpdateOrganizationRestricted}
        />

        <RemoveImageButton
          disabled={
            !Boolean(organization?.logoUri) || !Boolean(organizationLogo)
          }
          onClick={() => setOpenConfirm(true)}
          variant="text"
        >
          {getTranslation("REMOVE_TAG", t, i18n)}
        </RemoveImageButton>
      </ProfileAvatarContainer>
    </ErrorHandling>
  );
};

export default OrganizationHeaderDesktop;
